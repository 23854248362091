.userList {
    flex: 5;
    padding: 20px;
}

.userListTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.userListUser {
    display: flex;
    align-items: center;
}

.userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.userListEdit {
    border: none;
    border-radius: 5px;
    padding: 10px 40px;
    background-color: lightblue;
    cursor: pointer;
    margin-right: 40px;
}

.userListDelete {
    cursor: pointer;
}

.userAddButton {
    border: none;
    width: 150px;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 600;
    background-color: lightblue;
    cursor: pointer;
    margin-right: 40px;
}