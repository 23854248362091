.newUser {
    flex: 5;
    padding: 20px;
}

.newUserForm {
    display: flex ;
    flex-wrap: wrap;
}

.newUserItem {
    width: 550px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 40px;
}

.newUserItem > label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
}

.newUserItem > input {
    height: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}

.newUserRole > label,
.newUserGroup > label {
    margin-right: 20px;
}

.newUserRole > input,
.newUserGroup > label > input {
    margin-right: 5px;
}

.newUserButton {
    width: 150px;
    border: none;
    background-color: lightblue;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 40px;
}