.user {
    flex: 5;
    padding: 20px;
}

.userTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.userContainer {
    display: flex;
}

.userShow {
    flex: 1;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0, 0.75); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0, 0.75);
    padding: 20px;
    margin-right: 40px;
}

.userUpdate {
    flex: 3;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0, 0.75); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0, 0.75);
    padding: 20px;
    margin-right: 40px;
}

.userShowTop {
    display: flex;
    align-items: center;
}

.userShowImg{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
}
.userShowTopTitle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.userShowUserName {
    font-weight: 600;
}

.userShowUserStore {
    font-weight: 300;
}

.userShowBottom {
    margin-top: 20px;
}

.userShowTitle {
    font-size: 14px;
    font-weight: 600;
    color: rgb(186,186,186);
}

.userShowInfo {
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #555;
}

.userShowIcon {
    font-size: 16px !important;
}

.userShowInfoTitle {
    margin-left: 10px;
}

.userUpdateTitle {
    font-size: 24px;
    font-weight: 600;
}

.userUpdateForm {
    display: flex;
    margin-top: 20px;
}

.userUpdateItem {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.userUpdateItem > label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.userUpdateInput {
    border: none;
    width: 250px;
    height: 25px;
    border-bottom: 1px solid gray;
}

.userUpdateRole > input,
.userUpdateGroup > label > input {
    margin-right: 5px;
}
.userUpdateRole > label,
.userUpdateGroup > label{
    margin-right: 20px;
}


.userUpdateRight {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
}

.userUpdateButton {
    border: none;
    border-radius: 5px;
    background-color: lightblue;
    cursor: pointer;
    font-weight: 600;
    padding: 10px 20px;
}